























































































import { defineComponent, reactive } from "@vue/composition-api";
import validator from "validator";
import { checkVAT, countries } from "jsvat";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        invoicePurchaser: " none",
        invoiceName: "",
        invoiceStreet: "",
        invoicePostalCode: "",
        invoiceCity: "",
        invoiceVatNumber: "",
        invoiceConsent: false,
      }),
    },
  },

  setup(_, { root }) {
    const state = reactive({
      otherEmail: false,
    });

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          root.$tc("layout.misc.validZipCode"),
      ],
      vatNumber: [
        (v: string) =>
          !v ||
          checkVAT(v, countries).isValid ||
          root.$tc("layout.misc.validVatNumber"),
      ],
      nipNumber: [
        (v: string) =>
          !v ||
          (v.length >= 8 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validNipNumber"),
      ],
    };

    return { state, rules };
  },
});
